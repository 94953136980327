<template>
  <div class="content" style="width: 600px">
    <validation-observer v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="row">
          <validation-provider tag="div" class="col-md-6" name="名称" rules="required" v-slot="{ errors }">
            <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
              <label>名称</label>
              <input v-model="form.name" type="text" class="form-control" placeholder="请输入名称" autocomplete="off" />
              <span class="help-block">{{ errors[0] }}</span>
            </div>
          </validation-provider>

          <validation-provider tag="div" class="col-md-6" name="登录账号" rules="required" v-slot="{ errors }">
            <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
              <label>登录账号</label>
              <input v-model="form.account" type="text" class="form-control" placeholder="请输入登录账号" autocomplete="off" />
              <span class="help-block">{{ errors[0] }}</span>
            </div>
          </validation-provider>

          <validation-provider tag="div" class="col-md-6" name="登录密码" rules="required" v-slot="{ errors }">
            <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
              <label>登录密码</label>
              <input v-model="form.password" type="text" class="form-control" placeholder="请输入登录密码" autocomplete="off" />
              <span class="help-block">{{ errors[0] }}</span>
            </div>
          </validation-provider>

          <validation-provider tag="div" class="col-md-6" name="工号" rules="required" v-slot="{ errors }">
            <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
              <label>工号</label>
              <input v-model="form.job_code" type="text" class="form-control" placeholder="请输入工号" autocomplete="off" />
              <span class="help-block">{{ errors[0] }}</span>
            </div>
          </validation-provider>

          <validation-provider tag="div" class="col-md-6" name="受理工号" rules="required" v-slot="{ errors }">
            <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
              <label>受理工号</label>
              <input v-model="form.accept_job_code" type="text" class="form-control" placeholder="请输入受理工号" autocomplete="off" />
              <span class="help-block">{{ errors[0] }}</span>
            </div>
          </validation-provider>

          <validation-provider tag="div" class="col-md-12" name="所在部门" rules="required" v-slot="{ errors }">
            <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
              <label>所在部门</label>
              <select v-model="form.department_id" class="form-control" placeholder="请选择" autocomplete="off">
                <option v-for="(item, i) in orgList" :key="i" :value="item.id">{{ item.name }}</option>
              </select>
              <span class="help-block">{{ errors[0] }}</span>
            </div>
          </validation-provider>

          <validation-provider tag="div" class="col-md-12" name="角色" rules="required" v-slot="{ errors }">
            <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
              <label>角色</label>
              <select v-model="form.role_id" class="form-control" placeholder="请选择" autocomplete="off">
                <option v-for="(item, i) in roleList" :key="i" :value="item.id">{{ item.name }}</option>
              </select>
              <span class="help-block">{{ errors[0] }}</span>
            </div>
          </validation-provider>
          
        </div>
        <div class="form-group">
          <h-button type="submit" color="primary" :disabled="submitIng">提交</h-button>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { apiUserCreate, apiUserCreateDetail } from "@/apis/sys.api";
export default {
  components: {},
  data() {
    return {
      loading: false,
      submitIng: false,
      query: {},
      form: {
        name: "",
        department_id: "",
        role_id: "",
        account: "",
        password: "",
        job_code: "",
        accept_job_code: "",
      },
      orgList: [],
      roleList: [],
    };
  },
  mounted() {
    const _this = this;
    _this.loadDetail();
  },
  methods: {
    loadDetail() {
      const _this = this;
      _this.loading = true;
      apiUserCreateDetail(_this.query)
        .then((res) => {
          _this.submitIng = false;
          _this.orgList = res.org_list;
          _this.roleList = res.role_list;
        })
        .catch((err) => {
          _this.loading = false;
        });
    },
    onSubmit() {
      const _this = this;
      _this.submitIng = true;
      apiUserCreate(_this.form)
        .then(() => {
          _this.submitIng = false;
          _this.back(true);
          _this.$Message({
            type: "success",
            text: "操作成功",
          });
        })
        .catch((err) => {
          _this.submitIng = false;
          _this.$Message({
            type: "error",
            text: err.message,
          });
        });
    },
    back(status) {
      this.$emit("finish", status);
    },
  },
};
</script>
