import Vue from "vue";
import VueLocalStorage from "vue-localstorage";
import HeyUI from "heyui";
import {extend, configure, ValidationObserver, ValidationProvider} from "vee-validate";
import * as rules from "vee-validate/dist/rules";

import App from "@/components/app/Index.vue";
import router from "@/router/index";
import store from "@/store/index";
import i18n from "@/locale/i18n.locale";

configure({
  defaultMessage: (field, values) => {
    return i18n.t(`vee.${values._rule_}`, values);
  }
});

for (let name in rules) {
  extend(name, rules[name]);
}

Vue.use(HeyUI);
Vue.use(VueLocalStorage);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
