import { reqGet, reqPost } from "@/tools/request.tool";

export const apiBusGradeList = (param) => reqGet('/api/bus-grade/list', param);
export const apiBusGradeListType = (param) => reqGet('/api/bus-grade/list-type', param);
export const apiBusGradeListDetail = (param) => reqGet('/api/bus-grade/list-detail', param);
export const apiBusGradeCreateDetail = (param) => reqGet('/api/bus-grade/create-detail', param);
export const apiBusGradeCreate = (param) => reqPost('/api/bus-grade/create', param);
export const apiBusGradeUpdate = (param) => reqPost('/api/bus-grade/update', param);
export const apiBusGradeDelete = (param) => reqPost('/api/bus-grade/delete', param);

export const apiBusSourceList = (param) => reqGet('/api/bus-source/list', param);
export const apiBusSourceListDetail = (param) => reqGet('/api/bus-source/list-detail', param);
export const apiBusSourceCreate = (param) => reqPost('/api/bus-source/create', param);
export const apiBusSourceUpdate = (param) => reqPost('/api/bus-source/update', param);
export const apiBusSourceDelete = (param) => reqPost('/api/bus-source/delete', param);

export const apiBusTypeList = (param) => reqGet('/api/bus-type/list', param);
export const apiBusTypeListDetail = (param) => reqGet('/api/bus-type/list-detail', param);
export const apiBusTypeCreate = (param) => reqPost('/api/bus-type/create', param);
export const apiBusTypeUpdate = (param) => reqPost('/api/bus-type/update', param);
export const apiBusTypeDelete = (param) => reqPost('/api/bus-type/delete', param);
export const apiBusTypeFieldDetail = (param) => reqGet('/api/bus-type/field-detail', param);
export const apiBusTypeField = (param) => reqPost('/api/bus-type/field', param);

export const apiBusCaseListGrade = (param) => reqGet('/api/bus-case/list-grade', param);
export const apiBusCaseList = (param) => reqGet('/api/bus-case/list', param);
export const apiBusCaseListDetail = (param) => reqGet('/api/bus-case/list-detail', param);
export const apiBusCaseCreateDetail = (param) => reqGet('/api/bus-case/create-detail', param);
export const apiBusCaseCreate = (param) => reqPost('/api/bus-case/create', param);
export const apiBusCaseUpdate = (param) => reqPost('/api/bus-case/update', param);
export const apiBusCaseDelete = (param) => reqPost('/api/bus-case/delete', param);

export const apiBusCaseCodeList = (param) => reqGet('/api/bus-case-code/list', param);
export const apiBusCaseCodeListDetail = (param) => reqGet('/api/bus-case-code/list-detail', param);
export const apiBusCaseCodeCreate = (param) => reqGet('/api/bus-case-code/create', param);
export const apiBusCaseCodeUpdate = (param) => reqPost('/api/bus-case-code/update', param);
export const apiBusCaseCodeDelete = (param) => reqPost('/api/bus-case-code/delete', param);

export const apiBusList = (param) => reqGet('/api/bus/list', param);
export const apiBusListFiltrate = (param) => reqGet('/api/bus/list-filtrate', param);
export const apiBusListDetail = (param) => reqGet('/api/bus/list-detail', param);
export const apiBusCreateDetail = (param) => reqGet('/api/bus/create-detail', param);
export const apiBusCreate = (param) => reqPost('/api/bus/create', param);
export const apiBusUpdate = (param) => reqPost('/api/bus/update', param);
export const apiBusInTypeUpdate = (param) => reqPost('/api/bus/type-update', param);
export const apiBusInGradeUpdate = (param) => reqPost('/api/bus/grade-update', param);
export const apiBusInSourceUpdate = (param) => reqPost('/api/bus/source-update', param);
export const apiBusInBeginUpdate = (param) => reqPost('/api/bus/begin-update', param);
export const apiBusInStatusUpdate = (param) => reqPost('/api/bus/status-update', param);
export const apiBusInAcceptUpdate = (param) => reqPost('/api/bus/accept-update', param);
export const apiBusInCurPackageUpdate = (param) => reqPost('/api/bus/cur-package-update', param);
export const apiBusInDifferPackageUpdate = (param) => reqPost('/api/bus/differ-package-update', param);
export const apiBusInAfterPackageUpdate = (param) => reqPost('/api/bus/after-package-update', param);
export const apiBusInCaseUpdate = (param) => reqPost('/api/bus/case-update', param);
export const apiBusInCodeUpdate = (param) => reqPost('/api/bus/code-update', param);
export const apiBusInRemarkUpdate = (param) => reqPost('/api/bus/remark-update', param);
export const apiBusDelete = (param) => reqPost('/api/bus/delete', param);
export const apiBusExport = (param) => reqPost('/api/bus/export', param);
