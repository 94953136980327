<template>
  <div class="main-sidebar">
    <section class="sidebar">
      <ul class="sidebar-menu" data-widget="tree">
        <li class="header">导航</li>
        <template v-for="(item, i) in menus">
          <li :class="url === item.url ? 'active':''">
            <router-link :to="item.url">
              <i :class="'fa ' + item.icon"></i>
              <span>{{ item.name }}</span>
            </router-link>
          </li>
      </template>
      </ul>
    </section>
  </div>
</template>

<script>
import {mapState} from "vuex";
export default {
  components: {},
  computed: mapState({
    config: (state) => state.config,
    menus: (state) => state.authorize.menu,
    url: (state) => state.currentPage.url,
  }),
}
</script>
