<template>
  <div class="content">
    <validation-observer v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <validation-provider tag="div" name="名称" rules="required" v-slot="{ errors }">
          <div :class="errors[0] ? 'form-group has-error':'form-group'">
            <label>名称</label>
            <input v-model="form.name" type="text" class="form-control" placeholder="请输入名称" autocomplete="off">
            <span class="help-block">{{ errors[0] }}</span>
          </div>
        </validation-provider>
        <div class="form-group">
          <h-button type="submit" color="primary" :disabled="submitIng">提交</h-button>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { apiBusGradeListDetail, apiBusGradeUpdate } from "@/apis/bus.api";
export default {
  components: {},
  data() {
    return {
      loading: false,
      submitIng: false,
      query: {
        id: ''
      },
      form: {
        id: '',
        name: '',
      }
    };
  },
  props: ['eid'],
  mounted() {
    const _this = this;
    _this.loadDetail();
  },
  methods: {
    loadDetail() {
      const _this = this;
      _this.loading = true;
      _this.query.id = _this.eid;
      apiBusGradeListDetail(_this.query)
        .then((res) => {
          _this.loading = false;
          _this.form = JSON.parse(JSON.stringify(res.detail));
        })
        .catch(err => {
          _this.loading = false;
        })
    },
    onSubmit() {
      const _this = this;
      _this.submitIng = true;
      _this.form.id = _this.eid;
      apiBusGradeUpdate(_this.form)
        .then(() => {
          _this.submitIng = false;
          _this.back(true);
          _this.$Message({
            type: 'success',
            text: '操作成功',
          });
        })
        .catch(err => {
          _this.submitIng = false;
          _this.$Message({
            type: 'error',
            text: err.message,
          });
        })
    },
    back(status) {
      this.$emit("finish", status);
    }
  },
}
</script>
