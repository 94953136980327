import { render, staticRenderFns } from "./Edit.vue?vue&type=template&id=5c5eb3d6"
import script from "./Edit.vue?vue&type=script&lang=js"
export * from "./Edit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.6_css-loader@6.11.0_webpack@5.91.0__lodash@4.17.21_p_423ugilcy5ywpbmnv6d26kqajy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports