import { reqGet, reqPost } from "@/tools/request.tool";

export const apiLoginPc = (param) => reqPost('/api/login/pc', param);
export const apiAccountDetail = (param) => reqGet('/api/account/detail', param);
export const apiAccountLogout = (param) => reqPost('/api/account/logout', param);

// role
export const apiRoleList = (param) => reqGet('/api/role/list', param);
export const apiRoleListDetail = (param) => reqGet('/api/role/list-detail', param);
export const apiRoleCreate = (param) => reqPost('/api/role/create', param);
export const apiRoleUpdate = (param) => reqPost('/api/role/update', param);
export const apiRoleDelete = (param) => reqPost('/api/role/delete', param);
export const apiRoleRouteDetail = (param) => reqGet('/api/role/route-detail', param);
export const apiRoleRoute = (param) => reqPost('/api/role/route', param);

// org
export const apiOrgList = (param) => reqGet('/api/org/list', param);
export const apiOrgListDetail = (param) => reqGet('/api/org/list-detail', param);
export const apiOrgCreate = (param) => reqPost('/api/org/create', param);
export const apiOrgUpdate = (param) => reqPost('/api/org/update', param);
export const apiOrgDelete = (param) => reqPost('/api/org/delete', param);
export const apiOrgSeeDetail = (param) => reqGet('/api/org/see-detail', param);
export const apiOrgSee = (param) => reqPost('/api/org/see', param);

// user
export const apiUserList = (param) => reqGet('/api/user/list', param);
export const apiUserListDetail = (param) => reqGet('/api/user/list-detail', param);
export const apiUserCreateDetail = (param) => reqGet('/api/user/create-detail', param);
export const apiUserCreate = (param) => reqPost('/api/user/create', param);
export const apiUserUpdate = (param) => reqPost('/api/user/update', param);
export const apiUserDepartmentUpdate = (param) => reqPost('/api/user/department-update', param);
export const apiUserRoleUpdate = (param) => reqPost('/api/user/role-update', param);
export const apiUserJobCodeUpdate = (param) => reqPost('/api/user/job-code-update', param);
export const apiUserAcceptJobCodeUpdate = (param) => reqPost('/api/user/accept-job-code-update', param);
export const apiUserDelete = (param) => reqPost('/api/user/delete', param);
export const apiUserExport = (param) => reqPost('/api/user/export', param);
