import Vue from "vue";
import VueI18n from "vue-i18n";

import zh from 'heyui/dist/locale/zh-CN';
import zhCnVee from "vee-validate/dist/locale/zh_CN.json";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'zh_CN',
  messages: {
    zh_CN: Object.assign(zh, {
      vee: zhCnVee.messages,
    }),
  },
})

export default i18n;
