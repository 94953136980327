<template>
  <div class="content">
    <validation-observer v-slot="{ handleSubmit }" v-if="lists.length > 0">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <validation-provider tag="div" name="" rules="" v-slot="{ errors }">
          <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
            <label>名称</label>
            <div>
              <div v-for="(item, i) in lists" :key="i">
                <div>
                  <label style="margin-right: 10px">
                    <input type="checkbox" :value="item.code" v-model="form.field_list" />
                    <span style="margin-left: 6px">{{ item.name }}</span>
                  </label>
                </div>
              </div>
            </div>
            <span class="help-block">{{ errors[0] }}</span>
          </div>
        </validation-provider>
        <div class="form-group">
          <h-button type="submit" color="primary" :disabled="submitIng">提交</h-button>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { apiBusTypeFieldDetail, apiBusTypeField } from "@/apis/bus.api";
export default {
  components: {},
  data() {
    return {
      loading: false,
      submitIng: false,
      query: {
        id: "",
      },
      form: {
        id: "",
        field_list: [],
      },
      lists: [],
    };
  },
  props: ["eid"],
  mounted() {
    const _this = this;
    _this.loadDetail();
  },
  methods: {
    loadDetail() {
      const _this = this;
      _this.loading = true;
      _this.query.id = _this.eid;
      apiBusTypeFieldDetail(_this.query)
        .then((res) => {
          _this.loading = false;
          _this.form.field_list = res.select;
          _this.lists = res.select_list;
        })
        .catch((err) => {
          _this.loading = false;
        });
    },
    onSubmit() {
      const _this = this;
      _this.submitIng = true;
      _this.form.id = _this.eid;
      apiBusTypeField(_this.form)
        .then(() => {
          _this.submitIng = false;
          _this.back(true);
          _this.$Message({
            type: "success",
            text: "操作成功",
          });
        })
        .catch((err) => {
          _this.submitIng = false;
          _this.$Message({
            type: "error",
            text: err.message,
          });
        });
    },
    back(status) {
      this.$emit("finish", status);
    },
  },
};
</script>
