<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="box box-primary" v-if="auth.apiUserList">
          <div class="box-header with-border">
            <i class="fa fa-arrow-left" @click="back" style="cursor: pointer;"></i>
            <h3 class="box-title">员工详情信息</h3>
          </div>
          <div class="box-body">
            <div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label>名称</label>
                    <input v-model="form.name" type="text" class="form-control" @blur="editName(form)" :disabled="!auth.apiUserUpdate" autocomplete="off" />
                  </div>
                  <div class="form-group">
                    <label>所属部门</label>
                    <select v-model="form.department_id" class="form-control" @change="editDepartment(form)" :disabled="!auth.apiUserDepartment" autocomplete="off">
                      <option v-for="(item, i) in orgList" :key="i" :value="item.id">{{ item.name }}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label>角色</label>
                    <select v-model="form.role_id" class="form-control" @change="editRole(form)" :disabled="!auth.apiUserRole" autocomplete="off">
                      <option v-for="(item, i) in roleList" :key="i" :value="item.id">{{ item.name }}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label>工号</label>
                    <input v-model="form.job_code" type="text" class="form-control" @blur="editJobCode(form)" :disabled="!auth.apiUserJob" autocomplete="off" />
                  </div>
                  <div class="form-group">
                    <label>受理工号</label>
                    <input v-model="form.accept_job_code" type="text" class="form-control" @blur="editAcceptJobCode(form)" :disabled="!auth.apiUserAccept" autocomplete="off" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import qs from "qs";

import { apiUserListDetail, apiUserUpdate, apiUserDepartmentUpdate, apiUserRoleUpdate, apiUserJobCodeUpdate, apiUserAcceptJobCodeUpdate } from "@/apis/sys.api";

export default {
  components: {},
  data() {
    return {
      user: {},
      auth: {},
      params: {
        id: "",
      },
      query: {},
      loading: false,
      detail: {},
      orgList: [],
      roleList: [],
      form: {
        id: "",
        name: "",
        department_id: "",
        role_id: "",
        account: "",
        password: "",
        job_code: "",
        accept_job_code: "",
      },
    };
  },
  computed: mapState({
    config: (state) => state.config,
  }),
  mounted() {
    const _this = this;
    _this.query = Object.assign(_this.query, _this.$route.query);
    _this.params = Object.assign(_this.params, _this.$route.params);
    _this.loading = true;
    _this.authInit(_this).then((res) => {
      _this.user = res.user;
      _this.auth = res.auth;
      if (_this.auth.apiUserList) {
        _this.loadDetail();
      } else {
        _this.loading = false;
      }
    });
  },
  methods: {
    ...mapActions(["authInit"]),
    loadDetail() {
      const _this = this;
      _this.loading = true;
      apiUserListDetail({
        id: _this.params.id,
      })
        .then((res) => {
          _this.loading = false;
          _this.detail = res.detail;
          _this.form = JSON.parse(JSON.stringify(res.detail));
          _this.orgList = res.org_list;
          _this.roleList = res.role_list;
          _this.updateUrl();
        })
        .catch((err) => {
          _this.loading = false;
        });
    },
    updateUrl() {
      const _this = this;
      const url = _this.$route.path + "?" + qs.stringify(_this.query);
      _this.$router.replace({ path: url });
    },
    editName(item) {
      const _this = this;
      if (_this.auth.apiUserUpdate) {
        apiUserUpdate(item).then((res) => {}).catch((err) => {});
      }
    },
    editDepartment(item) {
      const _this = this;
      if (_this.auth.apiUserDepartment) {
        apiUserDepartmentUpdate(item).then((res) => {}).catch((err) => {});
      }
    },
    editRole(item) {
      const _this = this;
      if (_this.auth.apiUserRole) {
        apiUserRoleUpdate(item).then((res) => {}).catch((err) => {});
      }
    },
    editJobCode(item) {
      const _this = this;
      if (_this.auth.apiUserJob) {
        apiUserJobCodeUpdate(item).then((res) => {}).catch((err) => {});
      }
    },
    editAcceptJobCode(item) {
      const _this = this;
      if (_this.auth.apiUserAccept) {
        apiUserAcceptJobCodeUpdate(item).then((res) => {}).catch((err) => {});
      }
    },
    back() {
      this.$router.back();
    }
  },
};
</script>
