<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="box box-primary" v-if="auth.apiBusList">
          <div class="box-header with-border">
            <i class="fa fa-arrow-left" @click="back" style="cursor: pointer"></i>
            <h3 class="box-title">详情信息</h3>
          </div>
          <div class="box-body">
            <div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label>受理号码</label>
                    <div style="display: flex">
                      <input v-model="detail.accept_code" type="text" class="form-control" style="background-color: #ffffff" :readonly="true" />
                      <div style="margin-left: 10px" v-if="detail.can && auth.apiBusUpdate">
                        <Button style="height: 40px; line-height: 40px; padding: 0; width: 80px" @click="updateStatus = true">修改</Button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>业务类型</label>
                    <div style="display: flex">
                      <input v-model="detail.type_name" type="text" class="form-control" style="background-color: #ffffff" :readonly="true" />
                      <div style="margin-left: 10px" v-if="detail.can && auth.apiBusType">
                        <Button style="height: 40px; line-height: 40px; padding: 0; width: 80px" @click="updateTypeStatus = true">修改</Button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>业务档次</label>
                    <div style="display: flex">
                      <input v-model="detail.grade_name" type="text" class="form-control" style="background-color: #ffffff" :readonly="true" />
                      <div style="margin-left: 10px" v-if="detail.can && auth.apiBusGrade">
                        <Button style="height: 40px; line-height: 40px; padding: 0; width: 80px" @click="updateGradeStatus = true">修改</Button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>受理时间</label>
                    <div style="display: flex">
                      <input v-model="detail.accept_at" type="text" class="form-control" style="background-color: #ffffff" :readonly="true" />
                      <div style="margin-left: 10px" v-if="detail.can && auth.apiBusAccept">
                        <Button style="height: 40px; line-height: 40px; padding: 0; width: 80px" @click="updateAcceptStatus = true">修改</Button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>生效时间</label>
                    <div style="display: flex">
                      <input v-model="detail.begin_name" type="text" class="form-control" style="background-color: #ffffff" :readonly="true" />
                      <div style="margin-left: 10px" v-if="detail.can && auth.apiBusBegin">
                        <Button style="height: 40px; line-height: 40px; padding: 0; width: 80px" @click="updateBeginStatus = true">修改</Button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>业务来源</label>
                    <div style="display: flex">
                      <input v-model="detail.source_name" type="text" class="form-control" style="background-color: #ffffff" :readonly="true" />
                      <div style="margin-left: 10px" v-if="detail.can && auth.apiBusSource">
                        <Button style="height: 40px; line-height: 40px; padding: 0; width: 80px" @click="updateSourceStatus = true">修改</Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>客户套餐</label>
                    <div style="display: flex">
                      <input v-model="detail.package_cur" type="text" class="form-control" style="background-color: #ffffff" :readonly="true" />
                      <div style="margin-left: 10px" v-if="detail.can && auth.apiBusCur">
                        <Button style="height: 40px; line-height: 40px; padding: 0; width: 80px" @click="updateCurPackageStatus = true">修改</Button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>套餐差价</label>
                    <div style="display: flex">
                      <input v-model="detail.package_differ" type="text" class="form-control" style="background-color: #ffffff" :readonly="true" />
                      <div style="margin-left: 10px" v-if="detail.can && auth.apiBusDiffer">
                        <Button style="height: 40px; line-height: 40px; padding: 0; width: 80px" @click="updateDifferPackageStatus = true">修改</Button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>客户转后套餐</label>
                    <div style="display: flex">
                      <input v-model="detail.package_after" type="text" class="form-control" style="background-color: #ffffff" :readonly="true" />
                      <div style="margin-left: 10px" v-if="detail.can && auth.apiBusAfter">
                        <Button style="height: 40px; line-height: 40px; padding: 0; width: 80px" @click="updateAfterPackageStatus = true">修改</Button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>营销方案</label>
                    <div style="display: flex">
                      <input v-model="detail.case_name" type="text" class="form-control" style="background-color: #ffffff" :readonly="true" />
                      <div style="margin-left: 10px" v-if="detail.can && auth.apiBusCase">
                        <Button style="height: 40px; line-height: 40px; padding: 0; width: 80px" @click="updateCaseStatus = true">修改</Button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>项目营销编码</label>
                    <div style="display: flex">
                      <input v-model="detail.case_code_name" type="text" class="form-control" style="background-color: #ffffff" :readonly="true" />
                    </div>
                  </div>
                  <div class="form-group">
                    <label>业务状态</label>
                    <div style="display: flex">
                      <input v-model="detail.handle_desc" type="text" class="form-control" style="background-color: #ffffff" :readonly="true" />
                      <div style="margin-left: 10px" v-if="detail.can && auth.apiBusStatus">
                        <Button style="height: 40px; line-height: 40px; padding: 0; width: 80px" @click="updateStatusStatus = true">修改</Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>备注信息</label>
                    <div style="display: flex">
                      <input v-model="detail.remark" type="text" class="form-control" style="background-color: #ffffff" :readonly="true" />
                      <div style="margin-left: 10px" v-if="detail.can && auth.apiBusRemark">
                        <Button style="height: 40px; line-height: 40px; padding: 0; width: 80px" @click="updateRemarkStatus = true">修改</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box box-primary" v-if="!auth.apiBusList">
          <div class="box-body">没有权限</div>
        </div>
      </div>
    </div>

    <div v-if="auth.apiBusUpdate">
      <h-modal v-model="updateStatus">
        <validation-observer v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(editSubmit)">
            <validation-provider tag="div" name="受理号码" rules="required" v-slot="{ errors }">
              <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
                <label>受理号码</label>
                <input v-model="form.accept_code" type="text" class="form-control" placeholder="请输入受理号码" autocomplete="off" />
                <span class="help-block">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <div class="form-group">
              <h-button type="submit" color="primary" :disabled="submitIng">提交</h-button>
            </div>
          </form>
        </validation-observer>
      </h-modal>
    </div>

    <div v-if="auth.apiBusType">
      <h-modal v-model="updateTypeStatus">
        <validation-observer v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(editTypeSubmit)">
            <validation-provider tag="div" name="业务类型" rules="required" v-slot="{ errors }">
              <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
                <label>业务类型</label>
                <select v-model="form.type_id" class="form-control" placeholder="请选择" autocomplete="off">
                  <option v-for="(item, i) in typeList" :key="i" :value="item.id">{{ item.name }}</option>
                </select>
                <span class="help-block">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <div class="form-group">
              <h-button type="submit" color="primary" :disabled="submitIng">提交</h-button>
            </div>
          </form>
        </validation-observer>
      </h-modal>
    </div>

    <div v-if="auth.apiBusGrade">
      <h-modal v-model="updateGradeStatus">
        <validation-observer v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(editGradeSubmit)">
            <validation-provider tag="div" name="业务档次" rules="required" v-slot="{ errors }">
              <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
                <label>业务档次</label>
                <select v-model="form.grade_id" class="form-control" placeholder="请选择" autocomplete="off">
                  <option v-for="(item, i) in gradeList" :key="i" :value="item.id">{{ item.name }}</option>
                </select>
                <span class="help-block">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <div class="form-group">
              <h-button type="submit" color="primary" :disabled="submitIng">提交</h-button>
            </div>
          </form>
        </validation-observer>
      </h-modal>
    </div>

    <div v-if="auth.apiBusAccept">
      <h-modal v-model="updateAcceptStatus">
        <validation-observer v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(editAcceptSubmit)">
            <validation-provider tag="div" name="受理时间" rules="required" v-slot="{ errors }">
              <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
                <label>受理时间</label>
                <div>
                  <h-date-picker v-model="form.accept_at" format="YYYY-MM-DD" placeholder="请输入受理时间" autocomplete="off"></h-date-picker>
                </div>
                <span class="help-block">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <div class="form-group">
              <h-button type="submit" color="primary" :disabled="submitIng">提交</h-button>
            </div>
          </form>
        </validation-observer>
      </h-modal>
    </div>

    <div v-if="auth.apiBusBegin">
      <h-modal v-model="updateBeginStatus">
        <validation-observer v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(editBeginSubmit)">
            <validation-provider tag="div" name="生效时间" rules="required" v-slot="{ errors }">
              <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
                <label>生效时间</label>
                <select v-model="form.begin_type" class="form-control" placeholder="请选择" autocomplete="off">
                  <option v-for="(item, i) in beginTypeList" :key="i" :value="item.id">{{ item.name }}</option>
                </select>
                <span class="help-block">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <div class="form-group">
              <h-button type="submit" color="primary" :disabled="submitIng">提交</h-button>
            </div>
          </form>
        </validation-observer>
      </h-modal>
    </div>

    <div v-if="auth.apiBusSource">
      <h-modal v-model="updateSourceStatus">
        <validation-observer v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(editSourceSubmit)">
            <validation-provider tag="div" name="业务来源" rules="required" v-slot="{ errors }">
              <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
                <label>业务来源</label>
                <select v-model="form.source_id" class="form-control" placeholder="请选择" autocomplete="off">
                  <option v-for="(item, i) in sourceList" :key="i" :value="item.id">{{ item.name }}</option>
                </select>
                <span class="help-block">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <div class="form-group">
              <h-button type="submit" color="primary" :disabled="submitIng">提交</h-button>
            </div>
          </form>
        </validation-observer>
      </h-modal>
    </div>

    <div v-if="auth.apiBusCur">
      <h-modal v-model="updateCurPackageStatus">
        <validation-observer v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(editCurPackageSubmit)">
            <validation-provider tag="div" name="客户套餐" rules="" v-slot="{ errors }">
              <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
                <label>客户套餐</label>
                <input v-model="form.package_cur" type="text" class="form-control" placeholder="请输入客户套餐" autocomplete="off" />
                <span class="help-block">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <div class="form-group">
              <h-button type="submit" color="primary" :disabled="submitIng">提交</h-button>
            </div>
          </form>
        </validation-observer>
      </h-modal>
    </div>

    <div v-if="auth.apiBusDiffer">
      <h-modal v-model="updateDifferPackageStatus">
        <validation-observer v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(editDifferPackageSubmit)">
            <validation-provider tag="div" name="套餐差价" rules="" v-slot="{ errors }">
              <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
                <label>套餐差价</label>
                <input v-model="form.package_differ" type="text" class="form-control" placeholder="请输入套餐差价" autocomplete="off" />
                <span class="help-block">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <div class="form-group">
              <h-button type="submit" color="primary" :disabled="submitIng">提交</h-button>
            </div>
          </form>
        </validation-observer>
      </h-modal>
    </div>

    <div v-if="auth.apiBusAfter">
      <h-modal v-model="updateAfterPackageStatus">
        <validation-observer v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(editAfterPackageSubmit)">
            <validation-provider tag="div" name="客户转后套餐" rules="" v-slot="{ errors }">
              <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
                <label>客户转后套餐</label>
                <input v-model="form.package_after" type="text" class="form-control" placeholder="请输入客户转后套餐" autocomplete="off" />
                <span class="help-block">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <div class="form-group">
              <h-button type="submit" color="primary" :disabled="submitIng">提交</h-button>
            </div>
          </form>
        </validation-observer>
      </h-modal>
    </div>

    <div v-if="auth.apiBusCase">
      <h-modal v-model="updateCaseStatus">
        <validation-observer v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(editCaseSubmit)">
            <validation-provider tag="div" name="营销案" rules="required" v-slot="{ errors }">
              <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
                <label>营销案</label>
                <select v-model="form.case_id" class="form-control" placeholder="请选择" autocomplete="off">
                  <option v-for="(item, i) in caseList" :key="i" :value="item.id">{{ item.name }}</option>
                </select>
                <span class="help-block">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <div class="form-group">
              <h-button type="submit" color="primary" :disabled="submitIng">提交</h-button>
            </div>
          </form>
        </validation-observer>
      </h-modal>
    </div>

    <div v-if="auth.apiBusCode">
      <h-modal v-model="updateCodeStatus">
        <validation-observer v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(editCodeSubmit)">
            <validation-provider tag="div" name="项目营销编码" rules="required" v-slot="{ errors }">
              <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
                <label>项目营销编码</label>
                <select v-model="form.case_code_id" class="form-control" placeholder="请选择" autocomplete="off">
                  <option v-for="(item, i) in caseCodeList" :key="i" :value="item.id">{{ item.name }}</option>
                </select>
                <span class="help-block">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <div class="form-group">
              <h-button type="submit" color="primary" :disabled="submitIng">提交</h-button>
            </div>
          </form>
        </validation-observer>
      </h-modal>
    </div>

    <div v-if="auth.apiBusStatus">
      <h-modal v-model="updateStatusStatus">
        <validation-observer v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(editStatusSubmit)">
            <validation-provider tag="div" name="业务状态" rules="required" v-slot="{ errors }">
              <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
                <label>业务状态</label>
                <select v-model="form.status" class="form-control" placeholder="请选择" autocomplete="off">
                  <option v-for="(item, i) in statusList" :key="i" :value="item.id">{{ item.name }}</option>
                </select>
                <span class="help-block">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <div class="form-group">
              <h-button type="submit" color="primary" :disabled="submitIng">提交</h-button>
            </div>
          </form>
        </validation-observer>
      </h-modal>
    </div>

    <div v-if="auth.apiBusRemark">
      <h-modal v-model="updateRemarkStatus">
        <validation-observer v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(editRemarkSubmit)">
            <validation-provider tag="div" name="备注" rules="required" v-slot="{ errors }">
              <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
                <label>备注</label>
                <input v-model="form.remark" type="text" class="form-control" placeholder="请输入备注" autocomplete="off" />
                <span class="help-block">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <div class="form-group">
              <h-button type="submit" color="primary" :disabled="submitIng">提交</h-button>
            </div>
          </form>
        </validation-observer>
      </h-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import qs from "qs";

import {
  apiBusListDetail,
  apiBusUpdate,
  apiBusInTypeUpdate,
  apiBusInGradeUpdate,
  apiBusInSourceUpdate,
  apiBusInBeginUpdate,
  apiBusInAcceptUpdate,
  apiBusInStatusUpdate,
  apiBusInCurPackageUpdate,
  apiBusInDifferPackageUpdate,
  apiBusInAfterPackageUpdate,
  apiBusInCaseUpdate,
  apiBusInCodeUpdate,
  apiBusInRemarkUpdate,
} from "@/apis/bus.api";

export default {
  components: {},
  data() {
    return {
      loading: false,
      submitIng: false,
      user: {},
      auth: {},
      params: {
        id: "",
      },
      query: {},
      loading: false,
      detail: {},
      typeList: [],
      gradeList: [],
      sourceList: [],
      beginTypeList: [],
      caseList: [],
      caseCodeList: [],
      statusList: [],
      form: {
        id: "",
        accept_code: "",
        type_id: "",
        grade_id: "",
        accept_at: "",
        begin_type: "",
        source_id: "",
        package_cur: "",
        package_differ: "",
        package_after: "",
        case_id: "",
        case_code_id: "",
        remark: "",
      },
      updateStatus: false,
      updateTypeStatus: false,
      updateGradeStatus: false,
      updateAcceptStatus: false,
      updateBeginStatus: false,
      updateSourceStatus: false,
      updateCurPackageStatus: false,
      updateDifferPackageStatus: false,
      updateAfterPackageStatus: false,
      updateCaseStatus: false,
      updateCodeStatus: false,
      updateStatusStatus: false,
      updateRemarkStatus: false,
    };
  },
  computed: mapState({
    config: (state) => state.config,
  }),
  mounted() {
    const _this = this;
    _this.query = Object.assign(_this.query, _this.$route.query);
    _this.params = Object.assign(_this.params, _this.$route.params);
    _this.loading = true;
    _this.authInit(_this).then((res) => {
      _this.user = res.user;
      _this.auth = res.auth;
      if (_this.auth.apiBusList) {
        _this.loadDetail();
      } else {
        _this.loading = false;
      }
    });
  },
  methods: {
    ...mapActions(["authInit"]),
    loadDetail() {
      const _this = this;
      _this.loading = true;
      apiBusListDetail({
        id: _this.params.id,
      })
        .then((res) => {
          _this.loading = false;
          _this.detail = res.detail;
          _this.form = JSON.parse(JSON.stringify(res.detail));
          _this.typeList = res.type_list;
          _this.gradeList = res.grade_list;
          _this.sourceList = res.source_list;
          _this.beginTypeList = res.begin_type_list;
          _this.caseList = res.case_list;
          _this.caseCodeList = res.case_code_list;
          _this.statusList = res.status_list;
          _this.updateUrl();
        })
        .catch((err) => {
          _this.loading = false;
        });
    },
    updateUrl() {
      const _this = this;
      const url = _this.$route.path + "?" + qs.stringify(_this.query);
      _this.$router.replace({ path: url });
    },
    editSubmit() {
      const _this = this;
      apiBusUpdate(_this.form)
        .then((res) => {
          _this.close(true);
          _this.$Message({
            type: "success",
            text: "操作成功",
          });
        })
        .catch((err) => {
          _this.$Message({
            type: "error",
            text: err.message,
          });
        });
    },
    editTypeSubmit() {
      const _this = this;
      apiBusInTypeUpdate(_this.form)
        .then((res) => {
          _this.close(true);
          _this.$Message({
            type: "success",
            text: "操作成功",
          });
        })
        .catch((err) => {
          _this.$Message({
            type: "error",
            text: err.message,
          });
        });
    },
    editGradeSubmit() {
      const _this = this;
      apiBusInGradeUpdate(_this.form)
        .then((res) => {
          _this.close(true);
          _this.$Message({
            type: "success",
            text: "操作成功",
          });
        })
        .catch((err) => {
          _this.$Message({
            type: "error",
            text: err.message,
          });
        });
    },
    editAcceptSubmit() {
      const _this = this;
      apiBusInAcceptUpdate(_this.form)
        .then((res) => {
          _this.close(true);
          _this.$Message({
            type: "success",
            text: "操作成功",
          });
        })
        .catch((err) => {
          _this.$Message({
            type: "error",
            text: err.message,
          });
        });
    },
    editBeginSubmit() {
      const _this = this;
      apiBusInBeginUpdate(_this.form)
        .then((res) => {
          _this.close(true);
          _this.$Message({
            type: "success",
            text: "操作成功",
          });
        })
        .catch((err) => {
          _this.$Message({
            type: "error",
            text: err.message,
          });
        });
    },
    editSourceSubmit() {
      const _this = this;
      apiBusInSourceUpdate(_this.form)
        .then((res) => {
          _this.close(true);
          _this.$Message({
            type: "success",
            text: "操作成功",
          });
        })
        .catch((err) => {
          _this.$Message({
            type: "error",
            text: err.message,
          });
        });
    },
    editStatusSubmit() {
      const _this = this;
      apiBusInStatusUpdate(_this.form)
        .then((res) => {
          _this.close(true);
          _this.$Message({
            type: "success",
            text: "操作成功",
          });
        })
        .catch((err) => {
          _this.$Message({
            type: "error",
            text: err.message,
          });
        });
    },
    editCurPackageSubmit() {
      const _this = this;
      apiBusInCurPackageUpdate(_this.form)
        .then((res) => {
          _this.close(true);
          _this.$Message({
            type: "success",
            text: "操作成功",
          });
        })
        .catch((err) => {
          _this.$Message({
            type: "error",
            text: err.message,
          });
        });
    },
    editDifferPackageSubmit() {
      const _this = this;
      apiBusInDifferPackageUpdate(_this.form)
        .then((res) => {
          _this.close(true);
          _this.$Message({
            type: "success",
            text: "操作成功",
          });
        })
        .catch((err) => {
          _this.$Message({
            type: "error",
            text: err.message,
          });
        });
    },
    editAfterPackageSubmit() {
      const _this = this;
      apiBusInAfterPackageUpdate(_this.form)
        .then((res) => {
          _this.close(true);
          _this.$Message({
            type: "success",
            text: "操作成功",
          });
        })
        .catch((err) => {
          _this.$Message({
            type: "error",
            text: err.message,
          });
        });
    },
    editCaseSubmit() {
      const _this = this;
      apiBusInCaseUpdate(_this.form)
        .then((res) => {
          _this.close(true);
          _this.$Message({
            type: "success",
            text: "操作成功",
          });
        })
        .catch((err) => {
          _this.$Message({
            type: "error",
            text: err.message,
          });
        });
    },
    editCodeSubmit() {
      const _this = this;
      apiBusInCodeUpdate(_this.form)
        .then((res) => {
          _this.close(true);
          _this.$Message({
            type: "success",
            text: "操作成功",
          });
        })
        .catch((err) => {
          _this.$Message({
            type: "error",
            text: err.message,
          });
        });
    },
    editRemarkSubmit() {
      const _this = this;
      apiBusInRemarkUpdate(_this.form)
        .then((res) => {
          _this.close(true);
          _this.$Message({
            type: "success",
            text: "操作成功",
          });
        })
        .catch((err) => {
          _this.$Message({
            type: "error",
            text: err.message,
          });
        });
    },
    close(status) {
      const _this = this;
      _this.updateStatus = false;
      _this.updateTypeStatus = false;
      _this.updateGradeStatus = false;
      _this.updateAcceptStatus = false;
      _this.updateBeginStatus = false;
      _this.updateSourceStatus = false;
      _this.updateCurPackageStatus = false;
      _this.updateDifferPackageStatus = false;
      _this.updateAfterPackageStatus = false;
      _this.updateCaseStatus = false;
      _this.updateCodeStatus = false;
      _this.updateStatusStatus = false;
      _this.updateRemarkStatus = false;
      if (status) {
        _this.loadDetail();
      }
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
