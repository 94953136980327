<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="box box-primary" v-if="auth.apiBusList">
          <div class="box-header with-border">
            <h3 class="box-title">业务记录</h3>
            <div class="box-tools pull-right">
              <h-button @click="add" v-if="auth.apiBusCreate">新增</h-button>
            </div>
          </div>
          <div class="box-body no-padding">
            <form class="form-horizontal" style="padding: 10px; display: flex; align-items: center">
              <span>受理号码：</span>
              <input type="text" class="form-control" v-model="query.accept_code" style="width: 120px; margin-right: 10px" placeholder="受理号码" />
              <span>提单人员：</span>
              <input type="text" class="form-control" v-model="query.user_name" style="width: 120px; margin-right: 10px" placeholder="提单人员" />
              <span>受理时间：</span>
              <input type="date" class="form-control" v-model="query.accept_start" style="width: 120px;" placeholder="开始时间" />
              <span>至</span>
              <input type="date" class="form-control" v-model="query.accept_end" style="width: 120px; margin-right: 10px" placeholder="结束时间" />
              <span>来源：</span>
              <select v-model="query.source_id" class="form-control" style="width: 120px; margin-right: 10px" placeholder="请选择" autocomplete="off">
                <option v-for="(item, i) in sourceList" :key="i" :value="item.id">{{ item.name }}</option>
              </select>
              <span>类型：</span>
              <select v-model="query.type_id" class="form-control" style="width: 100px; margin-right: 10px" placeholder="请选择" autocomplete="off">
                <option v-for="(item, i) in typeList" :key="i" :value="item.id">{{ item.name }}</option>
              </select>
              <span>等级：</span>
              <select v-model="query.grade_id" class="form-control" style="width: 100px; margin-right: 10px" placeholder="请选择" autocomplete="off">
                <option v-for="(item, i) in gradeList" :key="i" :value="item.id">{{ item.name }}</option>
              </select>
              <span>状态：</span>
              <select v-model="query.status" class="form-control" style="width: 80px; margin-right: 10px" placeholder="请选择" autocomplete="off">
                <option :value="0">全部</option>
                <option :value="3">待操作</option>
                <option v-for="(item, i) in statusList" :key="i" :value="item.id">{{ item.name }}</option>
              </select>
              <span>排序：</span>
              <select v-model="query.sort" class="form-control" style="width: 100px; margin-right: 10px" placeholder="请选择" autocomplete="off">
                <option :value="0">默认排序</option>
                <option :value="1">受理时间升序</option>
                <option :value="2">受理时间倒序</option>
              </select>
              <button type="button" class="btn btn-info" @click="querySearch">搜索</button>
              <button type="button" class="btn btn" style="margin-left: 10px" v-if="auth.apiBusExport" @click="exportList" :disabled="exportIng">导出</button>
            </form>
            <div class="table-responsive mailbox-messages">
              <table class="table table-hover table-striped">
                <tbody>
                  <tr>
                    <th>受理号码</th>
                    <th>业务类型</th>
                    <th>业务档次</th>
                    <th>受理时间</th>
                    <th>生效时间</th>
                    <th>业务来源</th>
                    <th>提单人员</th>
                    <th>业务状态</th>
                    <th>客户套餐</th>
                    <th>套餐差价</th>
                    <th>转后套餐</th>
                    <th>营销案</th>
                    <th>项目营销编码</th>
                    <th>受理机构</th>
                    <th>受理工号</th>
                    <th>操作</th>
                  </tr>
                  <tr v-for="(item, i) in lists" :key="i">
                    <td>
                      {{ item.accept_code }}
                    </td>
                    <td>
                      {{ item.type_name }}
                    </td>
                    <td>
                      {{ item.grade_name }}
                    </td>
                    <td>
                      {{ item.accept_at }}
                    </td>
                    <td>
                      {{ item.begin_name }}
                    </td>
                    <td>
                      {{ item.source_name }}
                    </td>
                    <td>
                      {{ item.user_name }}
                    </td>
                    <td>
                      <span v-if="item.status === 5" class="h-tag h-tag-green">成功</span>
                      <span v-else-if="item.status === 6" class="h-tag h-tag-gray">失败</span>
                      <span v-else>
                        <select v-model="item.status" @change="editStatusSubmit(item)" placeholder="请选择" autocomplete="off">
                          <option :value="3">请选择</option>
                          <option v-for="(item, i) in statusList" :key="i" :value="item.id">{{ item.name }}</option>
                        </select>
                      </span>
                    </td>
                    <td>
                      {{ item.package_cur }}
                    </td>
                    <td>
                      {{ item.package_differ }}
                    </td>
                    <td>
                      {{ item.package_after }}
                    </td>
                    <td>
                      {{ item.case_name }}
                    </td>
                    <td>
                      {{ item.case_code_name }}
                    </td>
                    <td>
                      {{ item.department_name }}
                    </td>
                    <td>
                      {{ item.accept_job_code }}
                    </td>
                    <td class="mailbox-date">
                      <h-button size="xs" @click="detail(item)" v-if="auth.apiBusList" :text="true">详情</h-button>
                      <h-button size="xs" @click="del(item)" v-if="auth.apiBusDelete" :text="true">删除</h-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div style="padding: 10px">
            <h-pagination v-model="pagination" @change="pageChange" small layout="total,pager,jumper"></h-pagination>
          </div>
        </div>
        <div class="box box-primary" v-if="!auth.apiBusList">
          <div class="box-body">没有权限</div>
        </div>
      </div>
    </div>

    <div v-if="auth.apiBusCreate">
      <h-modal v-model="addStatus">
        <page-add @finish="finish"></page-add>
      </h-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import qs from "qs";

import { apiBusListFiltrate, apiBusList, apiBusDelete, apiBusExport, apiBusInStatusUpdate } from "@/apis/bus.api";

import PageAdd from "./Add.vue";

export default {
  components: { PageAdd },
  data() {
    return {
      loading: false,
      submitIng: false,
      exportIng: false,
      user: {},
      auth: {},
      params: {},
      query: {},
      loading: false,
      query: {
        accept_code: "",
        accept_start: "",
        accept_end: "",
        user_name: "",
        source_id: 0,
        grade_id: 0,
        type_id: 0,
        case_id: 0,
        case_code_id: 0,
        status: 0,
        page: 1,
        size: 10,
        sort: 0,
      },
      lists: [],
      total: 0,
      pagination: {},
      typeList: [],
      gradeList: [],
      sourceList: [],
      statusList: [],
      deleteIng: false,
      addStatus: false,
    };
  },
  computed: mapState({
    config: (state) => state.config,
  }),
  mounted() {
    const _this = this;
    _this.query = Object.assign(_this.query, _this.$route.query);
    _this.params = Object.assign(_this.params, _this.$route.params);
    _this.loading = true;
    _this.authInit(_this).then((res) => {
      _this.user = res.user;
      _this.auth = res.auth;
      if (_this.auth.apiBusList) {
        _this.loadFiltrate();
      } else {
        _this.loading = false;
      }
    });
  },
  methods: {
    ...mapActions(["authInit"]),
    loadFiltrate() {
      const _this = this;
      apiBusListFiltrate()
        .then((res) => {
          _this.typeList = res.type_list;
          _this.gradeList = res.grade_list;
          _this.sourceList = res.source_list;
          _this.statusList = res.status_list;
          _this.loadList();
        })
        .catch((err) => {
          _this.loading = false;
        });
    },
    loadList() {
      const _this = this;
      _this.loading = true;
      apiBusList(_this.query)
        .then((res) => {
          _this.loading = false;
          _this.updateUrl();
          _this.lists = res.list;
          _this.total = res.total;
          _this.pagination = res.pagination;
        })
        .catch((err) => {
          _this.loading = false;
        });
    },
    updateUrl() {
      const _this = this;
      const url = _this.$route.path + "?" + qs.stringify(_this.query);
      _this.$router.replace({ path: url });
    },
    querySearch() {
      const _this = this;
      _this.query.page = 1;
      _this.loadList();
    },
    pageChange(page) {
      const _this = this;
      _this.query.page = parseInt(page.page);
      _this.loadList();
    },
    add() {
      const _this = this;
      _this.addStatus = true;
    },
    detail(item) {
      const _this = this;
      _this.$router.push({
        path: _this.$route.path + "/d/" + item.id,
      });
    },
    finish(status) {
      const _this = this;
      _this.addStatus = false;
      if (status) {
        _this.loadList();
      }
    },
    del(item) {
      const _this = this;
      _this
        .$Confirm("是否执行当前操作？", "操作提示")
        .then(() => {
          _this.deleteIng = true;
          apiBusDelete({ id: item.id })
            .then(() => {
              _this.deleteIng = false;
              _this.loadList();
              _this.$Message({
                type: "success",
                text: "操作成功",
              });
            })
            .catch((err) => {
              _this.deleteIng = false;
              _this.$Message({
                type: "error",
                text: err.message,
              });
            });
        })
        .catch(() => {});
    },
    editStatusSubmit(item) {
      const _this = this;
      _this
        .$Confirm("是否执行当前操作？", "操作提示")
        .then(() => {
          apiBusInStatusUpdate({
            id: item.id,
            status: item.status,
          })
            .then((res) => {
              _this.$Message({
                type: "success",
                text: "操作成功",
              });
            })
            .catch((err) => {
              _this.$Message({
                type: "error",
                text: err.message,
              });
            });
        })
        .catch(() => {
          item.status = 3;
        });
    },
    exportList() {
      const _this = this;
      _this
        .$Confirm("是否执行当前操作？", "操作提示")
        .then(() => {
          _this.exportIng = true;
          apiBusExport(_this.query)
            .then((res) => {
              _this.exportIng = false;
              if (res.url) {
                window.location.href = res.url;
              }
            })
            .catch((err) => {
              _this.exportIng = false;
              _this.$Message({
                type: "error",
                text: err.message,
              });
            });
        })
        .catch(() => {});
    },
  },
};
</script>
