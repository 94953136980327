import Vue from "vue";
import VueLocalStorage from "vue-localstorage";
import axios from "axios";
import Qs from "qs";
import config from "@/config";

Vue.use(VueLocalStorage);

axios.defaults.baseURL = config.host;

/**
 * 网络请求异常处理
 * @param {*} error
 * @param {*} reject
 */
const responseError = (error, reject) => {
  reject({
    success: false,
    message: "Error！",
  });
};

/**
 * 请求成功处理
 * @param {*} response
 * @param {*} resolve
 * @param {*} reject
 */
const responseSuccess = (response, resolve, reject) => {
  const data = response.data;
  if (data.success) {
    resolve(data.data);
  } else {
    reject(data);
  }
};

/**
 * @desc 获取get请求
 * @param {*} url
 * @param {*} query
 */
export const reqGet = (url, query = {}) => {
  let headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    "App": "pc",
  };
  const session = Vue.localStorage.get("session")
  headers.Authorization = session;
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      headers: headers,
      params: query,
      method: "get",
    })
      .then((res) => responseSuccess(res, resolve, reject))
      .catch((error) => responseError(error, reject));
  });
};

/**
 * @desc 获取get请求
 * @param {*} url
 * @param {*} params
 */
export const reqPost = (url, params = {}) => {
  let headers = {
    "Content-Type": "application/json",
    "App": "pc",
  };
  const session = Vue.localStorage.get("session")
  headers.Authorization = session;
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      headers: headers,
      data: params,
      method: "post",
    })
      .then((res) => responseSuccess(res, resolve, reject))
      .catch((error) => responseError(error, reject));
  });
};

