<template>
  <router-view></router-view>
</template>

<script>
import {mapState} from "vuex";

export default {
  components: {},
  computed: mapState({
    config: (state) => state.config,
  }),
};
</script>

<style scoped>
</style>
