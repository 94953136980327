<template>
  <div class="content">
    <validation-observer v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <validation-provider tag="div" name="业务档次" rules="required" v-slot="{ errors }">
          <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
            <label>业务档次</label>
            <select v-model="form.grade_id" class="form-control" placeholder="请选择" autocomplete="off">
              <option v-for="(item, i) in gradeList" :key="i" :value="item.id">{{ item.name }}</option>
            </select>
            <span class="help-block">{{ errors[0] }}</span>
          </div>
        </validation-provider>
        <validation-provider tag="div" name="营销案名称" rules="required" v-slot="{ errors }">
          <div :class="errors[0] ? 'form-group has-error':'form-group'">
            <label>营销案名称</label>
            <input v-model="form.name" type="text" class="form-control" placeholder="请输入名称" autocomplete="off">
            <span class="help-block">{{ errors[0] }}</span>
          </div>
        </validation-provider>
        <validation-provider tag="div" name="营销案编码" rules="required" v-slot="{ errors }">
          <div :class="errors[0] ? 'form-group has-error':'form-group'">
            <label>营销案编码</label>
            <input v-model="form.code" type="text" class="form-control" placeholder="请输入营销案编码" autocomplete="off">
            <span class="help-block">{{ errors[0] }}</span>
          </div>
        </validation-provider>
        <div class="form-group">
          <h-button type="submit" color="primary" :disabled="submitIng">提交</h-button>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { apiBusCaseListDetail, apiBusCaseUpdate } from "@/apis/bus.api";
export default {
  components: {},
  data() {
    return {
      loading: false,
      submitIng: false,
      query: {
        id: ''
      },
      gradeList: [],
      form: {
        id: '',
        grade_id: '',
        name: '',
        code: ''
      }
    };
  },
  props: ['eid'],
  mounted() {
    const _this = this;
    _this.loadDetail();
  },
  methods: {
    loadDetail() {
      const _this = this;
      _this.loading = true;
      _this.query.id = _this.eid;
      apiBusCaseListDetail(_this.query)
        .then((res) => {
          _this.loading = false;
          _this.gradeList = res.grade_list;
          _this.form = JSON.parse(JSON.stringify(res.detail));
        })
        .catch(err => {
          _this.loading = false;
        })
    },
    onSubmit() {
      const _this = this;
      _this.submitIng = true;
      _this.form.id = _this.eid;
      apiBusCaseUpdate(_this.form)
        .then(() => {
          _this.submitIng = false;
          _this.back(true);
          _this.$Message({
            type: 'success',
            text: '操作成功',
          });
        })
        .catch(err => {
          _this.submitIng = false;
          _this.$Message({
            type: 'error',
            text: err.message,
          });
        })
    },
    back(status) {
      this.$emit("finish", status);
    }
  },
}
</script>
