// 接口域名
const host = "";
const storageHost = host ? host : "";

export default {
  host: host,
  storage: storageHost,
  // 登录页
  loginPage: "/web/login",
  // 登录主页
  homePage: "/web/bus",
};
