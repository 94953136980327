<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="box box-primary" v-if="auth.apiUserList">
          <div class="box-header with-border">
            <h3 class="box-title">员工管理</h3>
            <div class="box-tools pull-right">
              <h-button @click="add" v-if="auth.apiUserCreate">新增</h-button>
            </div>
          </div>
          <div class="box-body no-padding">
            <form class="form-horizontal" style="padding: 10px; display: flex; align-items: center">
              <span>名称：</span>
              <input type="text" class="form-control" v-model="query.name" style="width: 120px; margin-right: 10px" placeholder="名称" />
              <span>工号：</span>
              <input type="text" class="form-control" v-model="query.job_code" style="width: 120px; margin-right: 10px" placeholder="工号" />
              <span>受理工号：</span>
              <input type="text" class="form-control" v-model="query.accept_job_code" style="width: 120px; margin-right: 10px" placeholder="受理工号" />
              <button type="button" class="btn btn-info" @click="querySearch">搜索</button>
              <button type="button" class="btn btn" style="margin-left: 10px" v-if="auth.apiUserExport" @click="exportList" :disabled="exportIng">导出</button>
            </form>
            <div class="table-responsive mailbox-messages">
              <table class="table table-hover table-striped">
                <tbody>
                  <tr>
                    <th>名称</th>
                    <th>账号</th>
                    <th>工号</th>
                    <th>受理工号</th>
                    <th>所在部门</th>
                    <th>操作</th>
                  </tr>
                  <tr v-for="(item, i) in lists" :key="i">
                    <td>
                      {{ item.name }}
                    </td>
                    <td>
                      {{ item.account }}
                    </td>
                    <td>
                      {{ item.job_code }}
                    </td>
                    <td>
                      {{ item.accept_job_code }}
                    </td>
                    <td>
                      {{ item.department_name }}
                    </td>
                    <td class="mailbox-date">
                      <h-button size="xs" @click="detail(item)" v-if="auth.apiUserList" :text="true">详情</h-button>
                      <h-button size="xs" @click="del(item)" v-if="auth.apiUserDelete" :text="true">删除</h-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div style="padding: 10px">
            <h-pagination v-model="pagination" @change="pageChange" small layout="total,pager,jumper"></h-pagination>
          </div>
        </div>
        <div class="box box-primary" v-if="!auth.apiUserList">
          <div class="box-body">没有权限</div>
        </div>
      </div>
    </div>

    <div v-if="auth.apiUserCreate">
      <h-modal v-model="addStatus">
        <page-add @finish="finish"></page-add>
      </h-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import qs from "qs";

import { apiUserList, apiUserDelete, apiUserExport } from "@/apis/sys.api";

import PageAdd from "./Add.vue";

export default {
  components: { PageAdd },
  data() {
    return {
      loading: false,
      submitIng: false,
      exportIng: false,
      user: {},
      auth: {},
      params: {},
      query: {},
      loading: false,
      query: {
        name: "",
        job_code: "",
        accept_job_code: "",
        page: 1,
        size: 10,
      },
      lists: [],
      total: 0,
      pagination: {},
      deleteIng: false,
      addStatus: false,
    };
  },
  computed: mapState({
    config: (state) => state.config,
  }),
  mounted() {
    const _this = this;
    _this.query = Object.assign(_this.query, _this.$route.query);
    _this.params = Object.assign(_this.params, _this.$route.params);
    _this.loading = true;
    _this.authInit(_this).then((res) => {
      _this.user = res.user;
      _this.auth = res.auth;
      if (_this.auth.apiUserList) {
        _this.loadList();
      } else {
        _this.loading = false;
      }
    });
  },
  methods: {
    ...mapActions(["authInit"]),
    loadList() {
      const _this = this;
      _this.loading = true;
      apiUserList(_this.query)
        .then((res) => {
          _this.loading = false;
          _this.updateUrl();
          _this.lists = res.list;
          _this.total = res.total;
          _this.pagination = res.pagination;
        })
        .catch((err) => {
          _this.loading = false;
        });
    },
    updateUrl() {
      const _this = this;
      const url = _this.$route.path + "?" + qs.stringify(_this.query);
      _this.$router.replace({ path: url });
    },
    querySearch() {
      const _this = this;
      _this.query.page = 1;
      _this.loadList();
    },
    pageChange(page) {
      const _this = this;
      _this.query.page = parseInt(page.page);
      _this.loadList();
    },
    add() {
      const _this = this;
      _this.addStatus = true;
    },
    detail(item) {
      const _this = this;
      _this.$router.push({
        path: _this.$route.path + "/d/" + item.id,
      });
    },
    finish(status) {
      const _this = this;
      _this.addStatus = false;
      if (status) {
        _this.loadList();
      }
    },
    del(item) {
      const _this = this;
      _this
        .$Confirm("是否执行当前操作？", "操作提示")
        .then(() => {
          _this.deleteIng = true;
          apiUserDelete({ id: item.id })
            .then(() => {
              _this.deleteIng = false;
              _this.loadList();
              _this.$Message({
                type: "success",
                text: "操作成功",
              });
            })
            .catch((err) => {
              _this.deleteIng = false;
              _this.$Message({
                type: "error",
                text: err.message,
              });
            });
        })
        .catch(() => {});
    },
    exportList() {
      const _this = this;
      _this
        .$Confirm("是否执行当前操作？", "操作提示")
        .then(() => {
          _this.exportIng = true;
          apiUserExport()
            .then((res) => {
              _this.exportIng = false;
              if (res.url) {
                window.location.href = res.url;
              }
            })
            .catch((err) => {
              _this.exportIng = false;
              _this.$Message({
                type: "error",
                text: err.message,
              });
            });
        })
        .catch(() => {});
    },
  },
};
</script>
