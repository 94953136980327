<template>
  <div class="wrapper">
    <page-header></page-header>
    <page-menu></page-menu>
    <div class="content-wrapper">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

import PageMenu from "@/components/page-menu/Index.vue";
import PageHeader from "@/components/page-header/Index.vue";

export default {
  components: {
    PageMenu,
    PageHeader,
  },
  computed: mapState({
    config: (state) => state.config,
  }),
}
</script>

<style scoped></style>
