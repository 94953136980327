import Vue from "vue";
import VueLocalStorage from "vue-localstorage";
import VueRouter from "vue-router";

import config from "@/config";
import store from "@/store";
import pageRouter from "@/router/page.router";

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueLocalStorage);
Vue.use(VueRouter);

const checkIsLogin = () => {
  const session = Vue.localStorage.get("session")
  if (session) {
    return true
  } else {
    return false
  }
}

const router = new VueRouter({
  mode: "hash",
  routes: pageRouter,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  const url = to.path.split('/');
  if (url.length > 2) {
    const path = [url[0], url[1], url[2]].join('/')
    store.commit("updateCurrentPage", {
      title: to.meta.title,
      url: path,
    });
  } else {
    store.commit("updateCurrentPage", {
      title: to.meta.title,
      url: to.path,
    });
  }
  if (to.path == config.loginPage) {
    next()
  } else {
    if (checkIsLogin()) {
      next()
    } else {
      next({ path: config.loginPage, replace: true })
    }
  }
});

export default router;
