<template>
  <div class="hold-transition login-page" style="background: url('/static/image/photo24@2x.jpg')">
    <div class="login-big-box">
      <div class="login-left">
        <div class="login-left-img" style="background-image: url(/static/image/img-1.gif);">
        </div>
      </div>
      <div class="login-box">
        <div class="login-box-body">
          <h4 class="login-box-msg">登录你的账号</h4>
          <div v-if="formError" class="callout callout-danger">
            <p>{{ formError }}</p>
          </div>
          <validation-observer v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <validation-provider tag="div" name="账号" rules="required" v-slot="{ errors }">
                <div :class="errors[0] ? 'form-group has-error':'form-group'">
                  <label for="account">登录账号</label>
                  <input v-model="form.account" id="account" type="text" class="form-control" placeholder="请输入账号" autocomplete="off">
                  <span class="help-block">{{ errors[0] }}</span>
                </div>
              </validation-provider>
              <validation-provider tag="div" name="密码" rules="required" v-slot="{ errors }">
                <div :class="errors[0] ? 'form-group has-error':'form-group'">
                  <label for="password">登录密码</label>
                  <input v-model="form.password" id="password" type="password" class="form-control" placeholder="请输入密码" autocomplete="off">
                  <span class="help-block">{{ errors[0] }}</span>
                </div>
              </validation-provider>
              <div>
                <h-checkbox v-model="remember" :trueValue="1" :falseValue="0" @change="rememberAccount">记住登录</h-checkbox>
              </div>
              <div class="form-group">
                <div style="height: 10px;"></div>
                <h-button type="submit" color="primary" :block="true" >登录</h-button>
              </div>
            </form>
          </validation-observer>
          <div style="height: 10px;"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiAccountDetail, apiLoginPc } from "@/apis/sys.api";

import {mapState} from "vuex";
import qs from "qs";
export default {
  components: {},
  data() {
    return {
      params: {},
      query: {},
      formError: '',
      form: {
        account: '',
        password: '',
      },
      remember: 0
    };
  },
  computed: mapState({
    config: (state) => state.config,
  }),
  mounted() {
    const _this = this;
    _this.query = Object.assign(_this.query, _this.$route.query);
    _this.params = Object.assign(_this.params, _this.$route.params);
    _this.loadList()
  },
  methods: {
    loadList() {
      const _this = this;
      const remForm = _this.$localStorage.get("remember");
      if (remForm) {
        _this.form = JSON.parse(remForm);
        _this.remember = 1;
      } else {
        _this.remember = 0;
      }
      _this.updateUrl();
    },
    updateUrl() {
      const _this = this;
      const url = _this.$route.path + "?" + qs.stringify(_this.query);
      _this.$router.replace({ path: url });
    },
    rememberAccount() {
      const _this = this;
      const rem = parseInt(_this.remember);
      if (rem === 1) {
        _this.$localStorage.set("remember", JSON.stringify(_this.form));
      } else {
        _this.$localStorage.remove("remember");
      }
    },
    onSubmit() {
      const _this = this;
      _this.rememberAccount();
      apiLoginPc(_this.form)
        .then(res => {
          const session = res.type + ' ' + res.access_token;
          _this.$localStorage.set("session", session);
          apiAccountDetail()
            .then(userInfo => {
              _this.$localStorage.set("authorize", JSON.stringify(userInfo));
              _this.$router.replace({ path: _this.config.homePage })
            })
            .catch(err => {
              _this.formError = err.message;
              _this.$localStorage.remove("session");
              _this.$localStorage.remove("authorize");
            })
        })
        .catch(err => {
          _this.formError = err.message;
        })
    }
  },
}
</script>

<style scoped>
.login-page {
  height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-big-box {
  display: flex;
  background-color: #ffffff;
  height: 480px;
  overflow: hidden;
}

.login-box {
  display: block;
}

.form-group {
  margin-bottom: 6px;
}

.form-group label {
  font-weight: normal;
  color: #666666;
}

.login-box-msg {
  padding-top: 10px;
}

.help-block {
  display: block;
  height: 20px;
  margin-bottom: 0;
}

.login-left {
  padding: 10px;
  overflow: hidden;
}

.login-left-img {
  width: 400px;
  height: 100%;
  border-radius: 4px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;
}

.login-left-logo {
  margin-top: 10px;
  margin-left: 10px;
}
</style>
  