import Vue from "vue";
import Vuex from "vuex";
import config from "@/config";
import { apiAccountDetail } from "@/apis/sys.api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    config: config,
    authorize: {
      user: {},
      auth: {},
      menu: [],
      system: {
        name: '',
        logo: '',
      }
    },
    currentPage: {
      title: '',
      url: '',
    },
  },
  getters: {},
  mutations: {
    updateAuthorize(state, authorize) {
      state.authorize = authorize;
    },
    updateCurrentPage(state, currentPage) {
      state.currentPage = currentPage;
    },
  },
  actions: {
    authInit({commit}, _this) {
      const authorizeJson = _this.$localStorage.get("authorize");
      if (authorizeJson) {
        commit("updateAuthorize", JSON.parse(authorizeJson));
      }
      return new Promise((resolve) => {
        apiAccountDetail()
          .then(userInfo => {
            commit("updateAuthorize", userInfo);
            _this.$localStorage.set("authorize", JSON.stringify(userInfo));
            resolve(userInfo);
          })
          .catch(() => {
            _this.$localStorage.remove("session");
            _this.$localStorage.remove("authorize");
            _this.$router.replace({ path: config.loginPage });
          })
      });
    },
  },
  modules: {},
});
