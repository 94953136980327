<template>
  <div class="content">
    <validation-observer v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <validation-provider tag="div" name="" rules="" v-slot="{ errors }">
          <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
            <label>可看类型</label>
            <div>
              <label style="margin-right: 10px">
                <input type="radio" :value="0" v-model="form.is_see" />
                <span style="margin-left: 6px">看自己</span>
              </label>
            </div>
            <div>
              <label style="margin-right: 10px">
                <input type="radio" :value="1" v-model="form.is_see" />
                <span style="margin-left: 6px">看指定部门及下级</span>
              </label>
            </div>
            <span class="help-block">{{ errors[0] }}</span>
          </div>
        </validation-provider>
        <validation-provider v-if="form.is_see === 1" tag="div" name="" rules="" v-slot="{ errors }">
          <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
            <label>选择可看部门数据</label>
            <div v-for="(item, a) in lists" :key="a" >
              <label style="margin-right: 10px">
                <input type="checkbox" :value="item.id" v-model="form.department_id_list" />
                <span style="margin-left: 6px">{{ item.name }}</span>
              </label>
            </div>
            <span class="help-block">{{ errors[0] }}</span>
          </div>
        </validation-provider>
        <div class="form-group">
          <h-button type="submit" color="primary" :disabled="submitIng">提交</h-button>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { apiOrgSee, apiOrgSeeDetail } from "@/apis/sys.api";
export default {
  components: {},
  data() {
    return {
      loading: false,
      submitIng: false,
      query: {
        id: "",
      },
      form: {
        id: "",
        is_see: 0,
        department_id_list: [],
      },
      lists: [],
    };
  },
  props: ["eid"],
  mounted() {
    const _this = this;
    _this.loadDetail();
  },
  methods: {
    loadDetail() {
      const _this = this;
      _this.loading = true;
      _this.query.id = _this.eid;
      apiOrgSeeDetail(_this.query)
        .then((res) => {
          _this.loading = false;
          _this.form = JSON.parse(JSON.stringify(res.detail));
          _this.lists = res.list;
        })
        .catch((err) => {
          _this.loading = false;
        });
    },
    onSubmit() {
      const _this = this;
      _this.submitIng = true;
      _this.form.id = _this.eid;
      apiOrgSee(_this.form)
        .then(() => {
          _this.submitIng = false;
          _this.back(true);
          _this.$Message({
            type: "success",
            text: "操作成功",
          });
        })
        .catch((err) => {
          _this.submitIng = false;
          _this.$Message({
            type: "error",
            text: err.message,
          });
        });
    },
    back(status) {
      this.$emit("finish", status);
    },
  },
};
</script>
