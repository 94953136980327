<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="box box-primary" v-if="auth.apiBusCaseList">
          <div class="box-header with-border">
            <h3 class="box-title">列表</h3>
            <div class="box-tools pull-right">
              <h-button @click="add" v-if="auth.apiBusCaseCreate">新增</h-button>
            </div>
          </div>
          <div class="box-body no-padding">
            <form class="form-horizontal" style="padding: 10px; display: flex; align-items: center">
              <span>业务档次：</span>
              <select v-model="query.grade_id" class="form-control" style="width: 420px; margin-right: 10px" placeholder="请选择" autocomplete="off">
                <option v-for="(item, i) in gradeList" :key="i" :value="item.id">{{ item.name }}</option>
              </select>
              <button type="button" class="btn btn-info" @click="querySearch">搜索</button>
            </form>
            <div class="table-responsive mailbox-messages">
              <table class="table table-hover table-striped">
                <tbody>
                  <tr>
                    <th>所属档次</th>
                    <th>名称</th>
                    <th>营销案编码</th>
                    <th>操作</th>
                  </tr>
                  <tr v-for="(item, i) in lists" :key="i">
                    <td class="mailbox-name">
                      {{ item.grade_name }}
                    </td>
                    <td class="mailbox-name">
                      {{ item.name }}
                    </td>
                    <td class="mailbox-name">
                      {{ item.code }}
                    </td>
                    <td class="mailbox-date">
                      <h-button size="xs" @click="edit(item)" v-if="auth.apiBusCaseUpdate" :text="true">修改</h-button>
                      <h-button size="xs" @click="del(item)" v-if="auth.apiBusCaseDelete" :text="true">删除</h-button>
                    </td>
                  </tr>
                  <tr v-if="lists.length === 0">
                    <td rowspan="4">没有数据</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="box box-primary" v-if="!auth.apiBusCaseList">
          <div class="box-body">
            没有权限
          </div>
        </div>
      </div>
    </div>

    <div v-if="auth.apiBusCaseCreate">
      <h-modal v-model="addStatus">
        <page-add @finish="finish"></page-add>
      </h-modal>
    </div>

    <div v-if="auth.apiBusCaseUpdate">
      <h-modal v-model="editStatus">
        <page-edit :eid="editItem.id" @finish="finish"></page-edit>
      </h-modal>
    </div>

  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import qs from "qs";

import { apiBusCaseList, apiBusCaseListGrade, apiBusCaseDelete } from "@/apis/bus.api";

import PageAdd from "./Add.vue";
import PageEdit from "./Edit.vue";

export default {
  components: { PageAdd, PageEdit },
  data() {
    return {
      user: {},
      auth: {},
      params: {},
      query: {
        grade_id: 0
      },
      loading: false,
      lists: [],
      editItem: {},
      deleteIng: false,
      addStatus: false,
      editStatus: false,
      gradeList: [],
    };
  },
  computed: mapState({
    config: (state) => state.config,
  }),
  mounted() {
    const _this = this;
    _this.query = Object.assign(_this.query, _this.$route.query);
    _this.params = Object.assign(_this.params, _this.$route.params);
    _this.loading = true;
    _this.authInit(_this).then((res) => {
      _this.user = res.user;
      _this.auth = res.auth;
      if (_this.auth.apiBusCaseList) {
        _this.loadList();
      } else {
        _this.loading = false;
      }
    });
  },
  methods: {
    ...mapActions(["authInit"]),
    loadList() {
      const _this = this;
      _this.loading = true;
      apiBusCaseList(_this.query)
        .then((res) => {
          _this.updateUrl();
          _this.lists = res.list;
          _this.loading = false;
          apiBusCaseListGrade()
            .then((res) => {
              _this.gradeList = res.grade_list;
            })
            .catch((err) => {});
        })
        .catch((err) => {
          _this.loading = false;
        });
    },
    querySearch() {
      const _this = this;
      _this.loadList();
    },
    updateUrl() {
      const _this = this;
      const url = _this.$route.path + "?" + qs.stringify(_this.query);
      _this.$router.replace({ path: url });
    },
    add() {
      const _this = this;
      _this.addStatus = true;
      _this.editStatus = false;
      _this.editItem = {};
    },
    edit(item) {
      const _this = this;
      _this.addStatus = false;
      _this.editStatus = true;
      _this.editItem = item;
    },
    finish(status) {
      const _this = this;
      _this.addStatus = false;
      _this.editStatus = false;
      _this.editItem = {};
      if (status) {
        _this.loadList();
      }
    },
    del(item) {
      const _this = this;
      _this
        .$Confirm("是否执行当前操作？", "操作提示")
        .then(() => {
          _this.deleteIng = true;
          apiBusCaseDelete({ id: item.id })
            .then(() => {
              _this.deleteIng = false;
              _this.loadList();
              _this.$Message({
                type: "success",
                text: "操作成功",
              });
            })
            .catch((err) => {
              _this.deleteIng = false;
              _this.$Message({
                type: "error",
                text: err.message,
              });
            });
        })
        .catch(() => {});
    },
  },
};
</script>
