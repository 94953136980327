<template>
  <div class="content">
    <validation-observer v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <validation-provider tag="div" name="" rules="" v-slot="{ errors }">
          <div :class="errors[0] ? 'form-group has-error':'form-group'">
            <label>名称</label>
            <div>
              <div v-for="(item, i) in lists" :key="i">
                <div>{{ item.name }}</div>
                <div>
                  <label v-for="(child, a) in item.children" :key="a" style="margin-right: 10px;">
                    <input type="checkbox"  :value="child.id" v-model="form.route_list"  />
                    <span style="margin-left: 6px;">{{ child.name }}</span>
                  </label>
                </div>
              </div>
            </div>
            <span class="help-block">{{ errors[0] }}</span>
          </div>
        </validation-provider>
        <div class="form-group">
          <h-button type="submit" color="primary" :disabled="submitIng">提交</h-button>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { apiRoleRouteDetail, apiRoleRoute } from "@/apis/sys.api";
export default {
  components: {},
  data() {
    return {
      loading: false,
      submitIng: false,
      query: {
        id: ''
      },
      form: {
        id: '',
        route_list: [],
      },
      lists: [],
    };
  },
  props: ['eid'],
  mounted() {
    const _this = this;
    _this.loadDetail();
  },
  methods: {
    loadDetail() {
      const _this = this;
      _this.loading = true;
      _this.query.id = _this.eid;
      apiRoleRouteDetail(_this.query)
        .then((res) => {
          _this.loading = false;
          _this.form.route_list = res.route_had;
          _this.lists = res.route_list
        })
        .catch(err => {
          _this.loading = false;
        })
    },
    onSubmit() {
      const _this = this;
      _this.submitIng = true;
      _this.form.id = _this.eid;
      apiRoleRoute(_this.form)
        .then(() => {
          _this.submitIng = false;
          _this.back(true);
          _this.$Message({
            type: 'success',
            text: '操作成功',
          });
        })
        .catch(err => {
          _this.submitIng = false;
          _this.$Message({
            type: 'error',
            text: err.message,
          });
        })
    },
    back(status) {
      this.$emit("finish", status);
    }
  },
}
</script>
