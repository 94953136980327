<template>
  <div class="content" style="width: 800px">
    <validation-observer v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="row">
          <validation-provider class="col-md-6" tag="div" name="受理号码" rules="required" v-slot="{ errors }">
            <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
              <label>受理号码</label>
              <input v-model="form.accept_code" type="text" class="form-control" placeholder="请输入受理号码" autocomplete="off" />
              <span class="help-block">{{ errors[0] }}</span>
            </div>
          </validation-provider>

          <validation-provider class="col-md-6" tag="div" name="业务类型" rules="required" v-slot="{ errors }">
            <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
              <label>业务类型</label>
              <select v-model="form.type_id" class="form-control"  @change="typeSelect" placeholder="请选择" autocomplete="off">
                <option v-for="(item, i) in typeList" :key="i" :value="item.id">{{ item.name }}</option>
              </select>
              <span class="help-block">{{ errors[0] }}</span>
            </div>
          </validation-provider>

          <validation-provider class="col-md-6" tag="div" name="业务档次" rules="required" v-slot="{ errors }">
            <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
              <label>业务档次</label>
              <select v-model="form.grade_id" class="form-control" @change="gradeSelect"  placeholder="请选择" autocomplete="off">
                <option v-for="(item, i) in gradeList" :key="i" :value="item.id">{{ item.name }}</option>
              </select>
              <span class="help-block">{{ errors[0] }}</span>
            </div>
          </validation-provider>

          <validation-provider class="col-md-6" tag="div" name="受理时间" rules="required" v-slot="{ errors }">
            <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
              <label>受理时间</label>
              <div>
                <h-date-picker v-model="form.accept_at" format="YYYY-MM-DD" placeholder="请输入受理时间" autocomplete="off" ></h-date-picker>
              </div>
              <span class="help-block">{{ errors[0] }}</span>
            </div>
          </validation-provider>

          <validation-provider class="col-md-6" tag="div" name="生效时间" rules="required" v-slot="{ errors }">
            <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
              <label>生效时间</label>
              <select v-model="form.begin_type" class="form-control" placeholder="请选择" autocomplete="off">
                <option v-for="(item, i) in beginTypeList" :key="i" :value="item.id">{{ item.name }}</option>
              </select>
              <span class="help-block">{{ errors[0] }}</span>
            </div>
          </validation-provider>

          <validation-provider class="col-md-6" tag="div" name="业务来源" rules="required" v-slot="{ errors }">
            <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
              <label>业务来源</label>
              <select v-model="form.source_id" class="form-control" placeholder="请选择" autocomplete="off">
                <option v-for="(item, i) in sourceList" :key="i" :value="item.id">{{ item.name }}</option>
              </select>
              <span class="help-block">{{ errors[0] }}</span>
            </div>
          </validation-provider>

          <validation-provider class="col-md-6" tag="div" name="客户套餐" rules="" v-slot="{ errors }">
            <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
              <label>客户套餐</label>
              <input v-model="form.package_cur" type="text" class="form-control" placeholder="请输入客户套餐" autocomplete="off" />
              <span class="help-block">{{ errors[0] }}</span>
            </div>
          </validation-provider>

          <validation-provider class="col-md-6" tag="div" name="套餐差价" rules="" v-slot="{ errors }">
            <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
              <label>套餐差价</label>
              <input v-model="form.package_differ" type="text" class="form-control" placeholder="请输入套餐差价" autocomplete="off" />
              <span class="help-block">{{ errors[0] }}</span>
            </div>
          </validation-provider>

          <validation-provider class="col-md-6" tag="div" name="客户转后套餐" rules="" v-slot="{ errors }">
            <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
              <label>客户转后套餐</label>
              <input v-model="form.package_after" type="text" class="form-control" placeholder="请输入客户转后套餐" autocomplete="off" />
              <span class="help-block">{{ errors[0] }}</span>
            </div>
          </validation-provider>

          <validation-provider class="col-md-6" tag="div" name="营销方案" rules="required" v-slot="{ errors }">
            <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
              <label>营销方案</label>
              <select v-model="form.case_id" class="form-control" @change="caseSelect" placeholder="请选择" autocomplete="off">
                <option v-for="(item, i) in caseList" :key="i" :value="item.id">{{ item.name }}</option>
              </select>
              <span class="help-block">{{ errors[0] }}</span>
            </div>
          </validation-provider>

          <validation-provider class="col-md-6" tag="div" name="项目营销编码" rules="" v-slot="{ errors }">
            <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
              <label>项目营销编码</label>
              <input v-model="form.case_code" :readonly="true" type="text" class="form-control" placeholder="项目营销编码" autocomplete="off" />
              <span class="help-block">{{ errors[0] }}</span>
            </div>
          </validation-provider>

          <validation-provider class="col-md-12" tag="div" name="" rules="" v-slot="{ errors }">
            <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
              <label>备注</label>
              <input v-model="form.remark" type="text" class="form-control" placeholder="请输入remark" autocomplete="off" />
              <span class="help-block">{{ errors[0] }}</span>
            </div>
          </validation-provider>
          
        </div>
        <div class="form-group">
          <h-button type="submit" color="primary" :disabled="submitIng">提交</h-button>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { apiBusCreate, apiBusCreateDetail } from "@/apis/bus.api";
export default {
  components: {},
  data() {
    return {
      loading: false,
      submitIng: false,
      query: {},
      form: {
        accept_code: "",
        type_id: "",
        grade_id: "",
        accept_at: "",
        begin_type: "",
        source_id: "",
        package_cur: "",
        package_after: "",
        package_differ: "",
        case_id: "",
        case: "",
        case_code: "",
        remark: "",
      },
      typeList: [],
      typeItem: {},
      gradeList: [],
      gradeItem: {},
      sourceList: [],
      beginTypeList: [],
      caseList: [],
      caseItem: {},
      caseCodeList: [],
      statusList: [],
    };
  },
  mounted() {
    const _this = this;
    _this.loadDetail();
  },
  methods: {
    loadDetail() {
      const _this = this;
      _this.loading = true;
      apiBusCreateDetail(_this.query)
        .then((res) => {
          _this.submitIng = false;
          _this.typeList = res.type_list;
          _this.gradeList = res.grade_list;
          _this.sourceList = res.source_list;
          _this.beginTypeList = res.begin_type_list;
          _this.caseList = res.case_list;
          _this.caseCodeList = res.case_code_list;
          _this.statusList = res.status_list;
        })
        .catch((err) => {
          _this.loading = false;
        });
    },
    typeSelect() {
      const _this = this;
      const typeList = _this.typeList;
      const typeId = _this.form.type_id;
      typeList.forEach(item => {
          if (item.id === typeId) {
            _this.typeItem = item;
            _this.form.grade_id = '';
            _this.form.case_id = '';
            _this.form.case_code = '';
            _this.gradeList = item.grade_list;
            _this.caseList = [];
          }
      })
    },
    gradeSelect() {
      const _this = this;
      const gradeList = _this.gradeList;
      const gradeId = _this.form.grade_id;
      gradeList.forEach(item => {
          if (item.id === gradeId) {
            _this.gradeItem = item;
            _this.form.case_id = '';
            _this.form.case_code = '';
            _this.caseList = item.case_list;
          }
      })
    },
    caseSelect() {
      const _this = this;
      const caseList = _this.caseList;
      const caseId = _this.form.case_id;
      caseList.forEach(item => {
          if (item.id === caseId) {
            _this.caseItem = item;
            _this.form.case_code = item.code;
          }
      })
    },
    onSubmit() {
      const _this = this;
      _this.submitIng = true;
      apiBusCreate(_this.form)
        .then(() => {
          _this.submitIng = false;
          _this.back(true);
          _this.$Message({
            type: "success",
            text: "操作成功",
          });
        })
        .catch((err) => {
          _this.submitIng = false;
          _this.$Message({
            type: "error",
            text: err.message,
          });
        });
    },
    back(status) {
      this.$emit("finish", status);
    },
  },
};
</script>
