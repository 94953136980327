import config from "@/config";
import GuestContent from "@/components/page-guest/Index.vue";
import PageContent from "@/components/page-content/Index.vue";

import Error404 from "@/views/error/404.vue";

import LoginIndex from "@/views/login/PageIndex.vue";

import BusIndex from "@/views/bus/PageIndex.vue";
import BusDetail from "@/views/bus/PageDetail.vue";
import BusGradeIndex from "@/views/bus_grade/PageIndex.vue";
import BusSourceIndex from "@/views/bus_source/PageIndex.vue";
import BusCaseIndex from "@/views/bus_case/PageIndex.vue";
import BusCaseCodeIndex from "@/views/bus_case_code/PageIndex.vue";
import BusTypeIndex from "@/views/bus_type/PageIndex.vue";
import EmployeeIndex from "@/views/employee/PageIndex.vue";
import EmployeeDetail from "@/views/employee/PageDetail.vue";
import OrgIndex from "@/views/org/PageIndex.vue";
import RoleIndex from "@/views/role/PageIndex.vue";

export default [
  { 
    path: '/', 
    redirect: config.homePage 
  },
  {
    path: "/web",
    component: GuestContent,
    children: [
      {
        path: config.loginPage,
        meta: { title: "登录" },
        component: LoginIndex,
      },
    ]
  },
  {
    path: "/web",
    component: PageContent,
    children: [
      {
        path: config.homePage,
        meta: { title: "业务记录" },
        component: BusIndex,
      },
      {
        path: 'bus/d/:id',
        meta: { title: "业务记录详情" },
        component: BusDetail,
      },
      {
        path: 'bus-grade',
        meta: { title: "业务档次" },
        component: BusGradeIndex,
      },
      {
        path: 'bus-source',
        meta: { title: "业务来源" },
        component: BusSourceIndex,
      },
      {
        path: 'bus-case',
        meta: { title: "业务营销案" },
        component: BusCaseIndex,
      },
      {
        path: 'bus-case-code',
        meta: { title: "项目营销编码" },
        component: BusCaseCodeIndex,
      },
      {
        path: 'bus-type',
        meta: { title: "业务类型" },
        component: BusTypeIndex,
      },
      {
        path: 'employee',
        meta: { title: "员工管理" },
        component: EmployeeIndex,
      },
      {
        path: 'employee/d/:id',
        meta: { title: "员工详情" },
        component: EmployeeDetail,
      },
      {
        path: 'org',
        meta: { title: "组织架构" },
        component: OrgIndex,
      },
      {
        path: 'role',
        meta: { title: "角色权限" },
        component: RoleIndex,
      },
    ]
  },
  { 
    path: '*', 
    meta: { title: "404" },
    component: Error404,
  },
];
